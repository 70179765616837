import React from "react"
import styled from "styled-components"
import CircleArrowIcon from "../Icons/CircleArrowIcon"
import TriangleIcon from "../Icons/TriangleIcon"
import BreakpointUp from "../Media/BreakpointUp"
import { StaticImage } from "gatsby-plugin-image"

const Card = styled.div`  
  display: flex;
  align-items: center;
  justify-content: center;
  background-color:#fff;   
  position: relative;
  text-align:center;
  margin-bottom:30px;
  border-radius:2px;
  text-align: center;
  min-height: 136px;
  box-shadow: 0 3px 20px rgba(0, 0, 0, .16);
  border-radius: 8px;

  h5 {
    color: #938888;
  }
  .corner-triangle {
    position: absolute;
    top: -2px;
    left: 0
  }

  &:hover {
    cursor: pointer;
  }
  &.active{
    box-shadow: 0 20px 30px rgb(0 0 0 / 20%);
    h5 {
      color: #000;
    }
    .corner-triangle {
      svg {
        path {
          fill: #574C4C;
        }
      }
    }
    button{
      svg{
        circle {
          fill:#D51333;
        }
      }
    }
  }
`
const CardFigure = styled.figure`
  margin: 0 auto 8px;
  .gatsby-image-wrapper{
    display:inline-block;
  }  
`
const CardTitle = styled.div`
  margin-bottom:0;
  font-weight:800;
  letter-spacing:1px;
  font-size: 21px;
  line-height: 34px;
`
const CardBody = styled.div`
  position: relative; 
  z-index: 1; 
  padding: 15px;
`
const CardAction = styled.div`
  width:40px;
  height:40px;
  display:flex;
  align-items:center;
  justify-content: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -15px;
  right:auto;
  ${BreakpointUp.lg`
    left:auto;
    transform: translateX(0);
    bottom: auto;
    right: -20px;  
  `}
  button{    
    width: 100%;
    height: 100%;
    transform: rotate(90deg);
    ${BreakpointUp.lg`
    transform: rotate(0);
    `}
  }
  &:hover, &:focus{
    svg{
      circle {
        fill:#D51333;
      }
    }
  }
`

const CardType = (props) => {
  const {  buildingtype, buildingtitle, handleTab, isActive } = props;

  return (
    <Card className={`card ${isActive ? 'active':''}`} onClick={handleTab}>
      <div className="corner-triangle"><TriangleIcon /></div>
      <CardBody>   
        <CardFigure className="card-img">
          { buildingtype==="Portable Buildings" &&
            <StaticImage src="../../images/portable-building.png" placeholder="blurred" alt="portable-building" />  
          }
          { buildingtype === "Steel Buildings" &&
            <StaticImage src="../../images/steel-building.png" placeholder="blurred" alt="steel-building"
            />  
          }
        </CardFigure>                             
        <CardTitle className="h5">{buildingtitle}</CardTitle>
      </CardBody>
      <CardAction><button type="button" aria-label="button"><CircleArrowIcon /></button></CardAction>
    </Card>
  )
}

export default CardType
